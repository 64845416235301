<script lang="ts">
    import { routes } from '$lib/routes';

    const menuItems = [
        { label: 'kubaparis', href: routes.kubaparis },
        { label: 'studio sh', href: routes.ssh_projects },
    ];
</script>

<div class="flex justify-between p-5">
    <div class="space-x-1 text-xl uppercase">
        {#each menuItems as { label, href }}
            <a
                {href}
                sveltekit:prefetch
                class="kubaparis-shadow dark:kubaparis-shadow-dark whitespace-nowrap rounded-full  bg-white px-2 pt-0.5 uppercase dark:bg-black"
            >
                {label}
            </a>
        {/each}
    </div>
</div>
