<script lang="ts">
    import { fade } from 'svelte/transition';
    export let open = false;
    export let whiteStroke = false;
    const duration = 60;
</script>

<button class="fixed top-3.5 right-5" on:click={() => (open = !open)}>
    <div class="relative h-14 w-14">
        {#if open}
            <!-- X Icon -->
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 96.07 96.45"
                transition:fade|local={{ duration }}
                class="absolute overflow-visible stroke-black"
                ><defs
                    ><style>
                        .b {
                            fill: none;
                            stroke: #1d1d1b;
                            stroke-linecap: round;
                            stroke-miterlimit: 10;
                            stroke-width: 6px;
                        }
                    </style></defs
                ><line class="b" x1="16.65" y1="1.5" x2="79.41" y2="94.95" /><line
                    class="b"
                    x1="1.5"
                    y1="79.89"
                    x2="94.57"
                    y2="16.56"
                /></svg
            >
        {:else}
            <!-- Three lines icon -->
            <svg
                transition:fade|local={{ duration }}
                class={`absolute overflow-visible ${
                    whiteStroke ? 'stroke-white' : 'stroke-black dark:stroke-white'
                } `}
                version="1.1"
                id="a"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 97.8 93.1"
                style="enable-background:new 0 0 97.8 93.1;"
                xml:space="preserve"
            >
                <style type="text/css">
                    .st0 {
                        fill: none;
                        stroke-width: 6;
                        stroke-linecap: round;
                        stroke-miterlimit: 10;
                    }
                </style>
                <line class="st0" x1="3.2" y1="3.3" x2="94.4" y2="34.3" />
                <line class="st0" x1="3.2" y1="30.7" x2="94.4" y2="61.7" />
                <line class="st0" x1="3.3" y1="58.1" x2="94.5" y2="89.1" />
            </svg>
        {/if}
    </div>
</button>
