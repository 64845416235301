<script>
    import { routes } from '$lib/routes';
    import { onMount } from 'svelte';
    import { createEventDispatcher } from 'svelte';
    import { navigating } from '$app/stores';
	const dispatch = createEventDispatcher();

    let mounted = false;
    let animate = !$navigating;
    let transitionDuration = 1;
    onMount(() => {
        mounted = true
        console.log('buttons mounted')
        setTimeout(()=>{
            console.log('buttons appeared')
            dispatch('buttons_appeared')
        },transitionDuration * 1000)
    });
</script>

<ul class="mr-5 flex h-[2rem] space-x-2 no-translate" class:translatedup={!mounted && animate} style="--transition: {"transform " + transitionDuration + "s ease"}">
    <li
        class="bubble kubaparis-shadow dark:kubaparis-shadow-dark bg-white   dark:bg-black"
    >
        <a
            href={routes.kubaparis}
            sveltekit:prefetch
            class="text-2xl text-inherit text-black dark:text-white"
        >
            kubaparis
        </a>
    </li>

    <li
        class="bubble kubaparis-shadow dark:kubaparis-shadow-dark bg-white  dark:bg-black"
    >
        <a href={routes.ssh} sveltekit:prefetch class="text-2xl text-black dark:text-white"
            >studio sh</a
        >
    </li>
</ul>

<style>
    .bubble {
        @apply relative whitespace-nowrap rounded-full px-3 pt-0.5 uppercase;
        transition-property: box-shadow;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 600ms;
    }

    .translatedup {
        transform: translateY(-300%) !important;
    }

    .no-translate {
        transform: translateY(0);
        transition: var(--transition);
    }

    li {
        color: theme(
            'colors.black'
        ); /** This is needed so the color is never inherited from the parent. */
    }
</style>
